<template>
  <div class="addenterprise">
    <div class="store_table">
      <div
        class="store_table_item"
        v-for="(item, ii) in orderType"
        :key="ii"
        @click="storeClick(item.id)"
        :class="item.id === typeIndex ? 'active' : ''"
      >
        <span>{{ item.code }}</span>
      </div>
    </div>
    <div class="essential_information" v-if="typeIndex == 1">
      <!-- <h4 v-if="typeIndex == 1">基本信息</h4> -->
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <div v-if="typeIndex == 1">
            <el-form-item label="企业头像:" prop="companyLogo">
              <!-- <img-big-upload
                :imageShow.sync="form.companyLogo"
                :addTitleShow="false"
                @removeImg="removeImgDians"
                @uploadOneImgShow="uploadOneImgShowDians"
              /> -->
              <uploadLogo
                :imageShow="form.companyLogo"
                @uploadOneImgShow="uploadOneImgShow"
              ></uploadLogo>
            </el-form-item>
            <el-form-item label="企业名称:" prop="companyName">
              <div class="companyName">
                <el-input
                  class="readonly_copyable"
                  disabled
                  v-model="form.companyName"
                  placeholder="请输入企业名称"
                />
                <!-- <el-checkbox
                  v-model="form.foshanMember"
                  @change="clcikChecked"
                  style="margin-left: 10px"
                  >佛山会员</el-checkbox
                > -->
              </div>
            </el-form-item>
            <el-form-item label="企业全称:" prop="companyFullName">
              <el-autocomplete
                v-model="form.companyFullName"
                :fetch-suggestions="querySearchAsyncaptName"
                placeholder="请输入企业全称"
                @select="handleSelectcaptName"
              >
                <template slot-scope="{ item }">
                  <div class="name">
                    <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                      item.companyFullName
                    }}</span>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="企业法人:" prop="legalPerson">
              <el-input disabled v-model="form.legalPerson" placeholder="请输入企业法人" />
            </el-form-item>
            <el-form-item label="企业电话:" prop="companyPhone">
              <el-input disabled v-model="form.companyPhone" placeholder="请输入企业电话" />
            </el-form-item>
            <el-form-item label="企业官网:" prop="officialWebsite">
              <el-input disabled v-model="form.officialWebsite" placeholder="请输入企业官网" />
            </el-form-item>
            <el-form-item label="企业邮箱:" prop="companyEmail">
              <el-input disabled v-model="form.companyEmail" placeholder="请输入企业邮箱" />
            </el-form-item>
            <el-form-item label="企业类型:" prop="enterpriseType">
              <el-input disabled v-model="form.enterpriseType" placeholder="请输入企业类型" />
            </el-form-item>
            <!-- <el-form-item label="所属行业:" prop="industry">
              <el-input v-model="form.industry" placeholder="请输入所属行业" />
            </el-form-item> -->
            <!-- <el-form-item label="所在地区:" prop="city">
              <div class="select_inner">
                <el-select
                  v-model="form.country"
                  placeholder="请选择国家"
                  disabled
                  style="margin-right: 10px"
                >
                  <el-option value="中国"> </el-option>
                </el-select>
                <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.city" placeholder="请选择地区">
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item> -->
            <el-form-item label="所在地区:" prop="district">
              <div class="select_inner">
                <el-select
                  disabled
                  v-model="form.province"
                  placeholder="请选择省份"
                  @change="citychange"
                >
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.city" placeholder="请选择市" @change="districtchange">
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.district" placeholder="请选择区">
                  <el-option
                    v-for="item in districtList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="企业地址:" prop="enterpriseAddress">
              <el-input disabled v-model="form.enterpriseAddress" placeholder="请输入企业地址" />
            </el-form-item>
            <el-form-item label="成立日期:" prop="establishedTime">
              <div class="date_picker">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="form.establishedTime"
                  type="date"
                  placeholder="选择日期"
                  disabled
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="参保人数:" prop="insuredPersonsNumber">
              <el-input disabled v-model="form.insuredPersonsNumber" placeholder="请输入参保人数" />
            </el-form-item>

            <el-form-item label="企业规模:" prop="enterpriseScale">
              <el-radio-group disabled v-model="form.enterpriseScale">
                <!-- <el-radio :label="'1'">特大型</el-radio> -->
                <el-radio :label="'L（大型）'">L（大型）</el-radio>
                <el-radio :label="'M（中型）'">M（中型）</el-radio>
                <el-radio :label="'S（小型）'">S（小型）</el-radio>
                <el-radio :label="'XS（小微）'">XS（小微）</el-radio>
                <!-- <el-radio :label="'6'">其他</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="融资轮次:" prop="companyFinancingRounds">
              <el-radio-group disabled v-model="form.companyFinancingRounds">
                <el-radio :label="'未融资'">未融资</el-radio>
                <el-radio :label="'种子轮'">种子轮</el-radio>
                <el-radio :label="'天使轮'">天使轮</el-radio>
                <el-radio :label="'pre-A轮'">pre-A轮</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业简介:" prop="enterpriseIntro">
              <el-input
                disabled
                v-model="form.enterpriseIntro"
                type="textarea"
                placeholder="请输入企业简介"
              />
            </el-form-item>

            <el-form-item label="关联合作伙伴" prop="">
              <el-autocomplete
                v-model="form.partnerName"
                :fetch-suggestions="
                  (res, cb) => {
                    return querySearchAsyncapts1(res, cb)
                  }
                "
                placeholder="请搜索合作伙伴"
                @select="
                  (res) => {
                    return handleSelectcapts1(res)
                  }
                "
              >
                <template slot-scope="{ item }">
                  <div class="name">
                    <img
                      :src="item.coverImage"
                      style="border-radius: 28px"
                      width="28px"
                      height="28px"
                      alt=""
                    />
                    <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                      item.partnerName
                    }}</span>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="关联智参中心" prop="">
              <el-autocomplete
                v-model="form.zcName"
                :fetch-suggestions="
                  (res, cb) => {
                    return querySearchAsyncapts2(res, cb)
                  }
                "
                placeholder="请搜索智参中心"
                @select="
                  (res) => {
                    return handleSelectcapts2(res)
                  }
                "
              >
                <template slot-scope="{ item }">
                  <div class="name">
                    <img
                      :src="item.coverImage"
                      style="border-radius: 28px"
                      width="28px"
                      height="28px"
                      alt=""
                    />
                    <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                      item.zcName
                    }}</span>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </div>
          <div style="color: #4e93fb">
            <div class="store_table_item active">
              <span>联络人信息</span>
            </div>
            <div
              class="input_flex"
              style="display: flex; margin-top: 20px"
              v-for="(item, index) in form.liaisonsCompanyList"
              :key="index"
            >
              <div>
                <el-form-item label="联络人:" prop="liaisonsName">
                  <el-input v-model="item.liaisonsName" placeholder="请输入联络人" />
                </el-form-item>
                <el-form-item label="联系方式:" prop="liaisonsContactWay">
                  <el-input
                    v-model="item.liaisonsContactWay"
                    placeholder="请输入联系方式"
                  />
                </el-form-item>
                <el-form-item label="联络人职位:" prop="liaisonsPosition">
                  <div class="radio_flex">
                    <el-radio-group v-model="item.liaisonsPosition">
                      <el-radio
                        v-for="(el, indexs) in liaisonsList"
                        :key="indexs"
                        :label="el.name"
                        >{{ el.name }}</el-radio
                      >

                      <el-radio :label="'其他'">其他</el-radio>
                    </el-radio-group>

                    <div
                      class="radio_input"
                      style="margin-left: 5px"
                      v-if="item.liaisonsPosition == '其他'"
                    >
                      <el-input v-model="item.other" placeholder="请输入联系方式" />
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div style="display: flex; font-size: 24px; margin-left: 10px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addenterprise"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delenterprise(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div style="margin-top: 30px" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="clcans">取消</el-button>
      </div>
    </div>
    <!-- 标签管理 -->
    <labelManagement ref="labelManagement" v-if="typeIndex == 2"></labelManagement>
    <!-- 半自动化标签 -->
    <robotizationlabel
      ref="robotizationlabel"
      :companyinfo="form"
      :activeId="id"
      v-show="typeIndex == 3"
    ></robotizationlabel>
  </div>
</template>

<script>
import uploadLogo from '../components/uploadLogo.vue'
import labelManagement from '../components/labelManagement.vue'
import robotizationlabel from '../components/robotizationlabel.vue'
import {
  getCompanyDetail,
  addcompany,
  editcompany,
  getcompany,
  getPartners,
  getSystemDataParam
} from '@/api/datement'
import { getWisdomGinsengList } from '@/api/channel'
import { getCityAll } from '@/api/demand'

export default {
  name: 'addenterprise',
  computed: {
    id() {
      return this.$route.query.id
    },
    headTabActive() {
      return this.$route.query.headTabActive * 1
    },
    customerType() {
      return this.$route.query.customerType
    },
    pageNum() {
      return this.$route.query.pageNum
    }
  },
  components: { uploadLogo, labelManagement, robotizationlabel },
  data() {
    return {
      form: {
        dataComId: '',
        chamberOfCommerceId: '', //关联商会ID
        city: '', //所在地区
        companyEmail: '', //公司邮箱
        companyFullName: '', //企业全称
        companyLogo:
          'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png', //企业logo
        companyName: '', //企业名称
        companyPhone: '', //公司电话
        district: '', //所在地区
        foshanMember: 0,
        createId: '',
        createTime: '',
        employees: '', //企业人数
        enterpriseAddress: '', //公司地址
        enterpriseIntro: '', //简介
        enterpriseScale: '大型', //企业规模
        enterpriseType: '', //企业类型
        // establishedTime: null, //成立时间
        establishedTime: '', //成立日期
        exhibitionHall: '', //是否有展厅;1是有0是没有
        companyFinancingRounds: '', //融资轮次
        id: '',
        industry: '', //	所属行业
        insuredPersonsNumber: '', //参保人数
        legalPerson: '', //法人
        officialWebsite: '', //公司官网
        operatingState: '',
        province: '', //所在省份
        secretaryGeneralId: '', //秘书长ID
        sharePictures: '', //分享链接的图片
        shareTitle: '', //分享链接的标题
        updateId: '',
        updateTime: '',
        wisdomGinsengId: '',
        partnerChannelId: '',
        enterpriseCategory: '',
        zcName: '',
        partnerName: '',
        customerType: '1',
        liaisonsCompanyList: [
          {
            clientCompanyId: '',
            createId: '',
            createTime: '',
            liaisonsCompanyId: '',
            liaisonsContactWay: '', //联络方式
            liaisonsName: '', //联络人姓名
            other: '',
            liaisonsPosition: '', //联络人职位
            updateId: '',
            updateTime: ''
          }
        ]
      }, //表单收集

      orderType: [
        {
          id: 1,
          code: '企业资料'
        }
      ],
      //企业标签查询
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        blacklist: null, //1是冻结，0是不冻结
        companyId: null, //企业id
        labelName: null, //标签名称
        labelType: null, //标签类别
        weightProportion: null //权重占比
      },
      //企业标签
      listData: [],
      //新增标签
      companyLabel: {
        blacklist: null,
        companyId: null,
        id: null,
        labelName: null,
        labelType: '011',
        parentId: null,
        weightProportion: null
      },
      //标签条数
      total: 0,
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      //秘书长
      generalist: [],
      //商会
      comlist: [],
      //tab切换
      typeIndex: 1,
      operatingLists: [], //省
      operatingList: [], //市
      districtList: [], //区
      liaisonsList: [
        {
          id: 1,
          name: 'CEO'
        },
        {
          id: 2,
          name: 'CIO'
        },
        {
          id: 3,
          name: 'IT负责人'
        },
        {
          id: 4,
          name: '项目负责人'
        }
      ],
      //查询标签一览
      paramcode: {
        codeType: null,
        parentId: null
      },
      //企业类别
      codelist: [],
      specilist1: [],
      specilist: [],
      param: {
        keyWord: null
      },
      params: {
        keyWord: null
      },
      rules: {
        // companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        companyFullName: [{ required: true, message: '请输入企业全称', trigger: 'blur' }]
        // officialWebsite: [{ required: true, message: '请输入企业官网', trigger: 'blur' }],
        // commodityName: [{ required: true, message: '请输入所在地区', trigger: 'blur' }],
        // enterpriseAddress: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        // establishedTime: [{ required: true, message: '请添加成立日期', trigger: 'change' }],
        // enterpriseScale: [{ required: true, message: '请输入企业规模', trigger: 'blur' }],
        // financingRounds: [{ required: true, message: '请勾选融资轮次', trigger: 'change' }],
        // enterpriseIntro: [{ required: true, message: '请输入企业简介', trigger: 'blur' }],
        // city: [{ required: true, message: '请选择地区', trigger: 'change' }],
        // insuredPersonsNumber: [{ required: true, message: '请输入参保人数', trigger: 'blur' }],
        // companyLogo: [{ required: true, message: '请上传logo', trigger: 'change' }]
        // secretaryGeneralId: [{ required: true, message: '请添加关联秘书长', trigger: 'change' }],
        // chamberOfCommerceId: [{ required: true, message: '请添加关联商会', trigger: 'change' }],
        // shareTitle: [{ required: true, message: '请输入分享链接标题', trigger: 'blur' }],
        // sharePictures: [{ required: true, message: '请上传分享链接图片', trigger: 'change' }]
      },
      restaurants: [],
      state: '',
      timeout: null,
      companyFullNameOptions: [] //企业全称
    }
  },
  created() {
    this.typeIndex = this.headTabActive
    if (this.id) {
      let arr = [
        {
          id: 2,
          code: '企业标签'
        },
        {
          id: 3,
          code: '半自动标签'
        }
      ]
      this.orderType = this.orderType.concat(arr)
      this.getcompany()
    }
    this.incity()
  },
  methods: {
    clcikChecked(val) {
      this.form.foshanMember = val
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.form.companyLogo = value
    },
    //查询企业信息回显
    async getcompany() {
      const { data: res } = await getCompanyDetail({ id: this.id })
      if (res.resultCode == 200) {
        Object.assign(this.form, res.data)
        this.$nextTick(() => {
          this.$refs.robotizationlabel.initData()
        })
        this.form.foshanMember = Boolean(this.form.foshanMember)
        this.form.id = this.id

        if (this.form.province && this.form.city) {
          this.incity(this.form.province, this.form.city)
        }
        if (!this.form.liaisonsCompanyList) {
          this.form.liaisonsCompanyList = [
            {
              clientCompanyId: '',
              createId: '',
              createTime: '',
              liaisonsCompanyId: '',
              liaisonsContactWay: '',
              liaisonsName: '',
              other: '',
              liaisonsPosition: '',
              updateId: '',
              updateTime: ''
            }
          ]
        } else {
          this.form.liaisonsCompanyList.forEach((i, index) => {
            let findVal = this.liaisonsList.find((item) => item.name === i.liaisonsPosition)
            if (!findVal) {
              this.$set(i, 'other', i.liaisonsPosition)
              i.liaisonsPosition = '其他'
            }
          })
        }
        if (!this.form.companyLogo) {
          this.form.companyLogo =
            'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png' //企业logo
        }
      }
    },
    //城市
    async incity(province, city) {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
        if (province) {
          this.citychange(province)
        }

        if (city) {
          this.operatingLists.forEach((o) => {
            if (this.form.city == o.name) {
              this.districtList = this.operatingLists.find((el) => el.name == city).cityResults
            }
          })
        }
      }
    },
    //选择省
    citychanges(item) {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.form.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
    },
    //选择区
    districtchanges(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          let isnum = item.indexOf('市')
          if (isnum == -1) {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          } else {
            this.form.city = item
            this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            this.operatingLists = []
            this.operatingLists.push(o)
          }
        }
      })
    },
    //选择市
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },
    async querySearchAsyncapts1(queryString, cb) {
      if (this.form.partnerName) {
        const res = await getPartners({
          keyWord: this.form.partnerName
        })
        if (res.data.resultCode == 200) {
          this.specilist1 = res.data.data
          cb(this.specilist1)
        }
      } else {
        cb(this.specilist1)
      }
    },

    //选择合作伙伴
    handleSelectcapts1(item) {
      this.form.partnerName = item.partnerName
      this.form.partnerChannelId = item.id
    },
    async querySearchAsyncapts2(queryString, cb) {
      if (this.form.zcName) {
        const res = await getWisdomGinsengList({
          keyWord: this.form.zcName
        })
        if (res.data.resultCode == 200) {
          this.specilist = res.data.data
          cb(this.specilist)
        }
      } else {
        cb(this.specilist)
      }
    },

    //选择智参中心
    handleSelectcapts2(item) {
      this.form.zcName = item.zcName
      this.form.wisdomGinsengId = item.id
    },
    //新增联络人
    addenterprise() {
      this.form.liaisonsCompanyList.push({
        clientCompanyId: '',
        createId: '',
        createTime: '',
        liaisonsCompanyId: '',
        liaisonsContactWay: '',
        liaisonsName: '',
        other: '',
        liaisonsPosition: '',
        updateId: '',
        updateTime: ''
      })
    },
    //删除新增联络人
    delenterprise(index) {
      this.form.liaisonsCompanyList.splice(index, 1)
    },

    storeClick(id) {
      this.typeIndex = id
      if (id == 3) {
        this.$refs.robotizationlabel.sing()
      } else if (id == 1) {
        // this.$refs.labelManagement.sing()
      }
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.sharePictures = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.sharePictures = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.companyLogo = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.companyLogo = ''
    },

    /** 提交按钮 */
    submitForm() {
      if (this.form.liaisonsCompanyList[0].liaisonsName) {
        this.form.liaisonsCompanyList.forEach((el) => {
          if (el.liaisonsPosition == '其他') {
            el.liaisonsPosition = el.other
          }
        })
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // console.log(this.form.id, 'this.form.id')
          if (!this.id) {
            addcompany(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            editcompany(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    clcans() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/customer/list',
        query: {
          changeNum: true
        }
      })
    },
    // 企业全称
    async querySearchAsyncaptName(queryString, cb) {
      if (this.form.companyFullName) {
        const res = await getSystemDataParam({
          companyFullName: this.form.companyFullName,
          customerType: this.customerType
        })
        if (res.data.resultCode == 200) {
          this.companyFullNameOptions = res.data.data
          cb(this.companyFullNameOptions)
        }
      } else {
        this.form.issueId = null
        cb(this.companyFullNameOptions)
      }
    },
    handleSelectcaptName(item) {
      this.form.companyFullName = item.companyFullName
      this.form.dataComId = item.companyCode
      if (item.companyCode) {
        this.form.id = item.companyCode
      }
      if (item.id) {
        this.form.id = item.id
      }
      // 根据企业全称回显其他数据
      for (let key in item) {
        if (
          item.hasOwnProperty(key) &&
          item[key] != 'null' &&
          item[key] !== '' &&
          item[key] !== null
        ) {
          this.form[key] = item[key]
        }
      }

      // this.form.id = item.id
      // this.form.city = item.city
      // this.form.companyAddress = item.companyAddress
      // this.form.companyBriefIntroduction = item.companyBriefIntroduction
      // this.form.companyCity = item.companyCity
      // this.form.companyCode = item.companyCode
      // this.form.companyEmail = item.companyEmail
      // this.form.companyEstablishTime = item.companyEstablishTime
      // this.form.companyFinancingRounds = item.companyFinancingRounds
      // this.form.companyFullName = item.companyFullName
      // this.form.companyLegalPerson = item.companyLegalPerson
      // this.form.companyLogo = item.companyLogo
      // this.form.companyName = item.companyName
      // this.form.companyProvince = item.companyProvince
      // this.form.companyTelephone = item.companyTelephone
      // this.form.companyWebsite = item.companyWebsite
      // this.form.enterpriseScale = item.enterpriseScale
      // this.form.socialSecurityNumber = item.socialSecurityNumber
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-checkbox-group {
  font-size: 0;
  width: 95%;
}
::v-deep .label_code .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

::v-deep .el-radio {
  padding: 5px 0px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  -moz-user-select: none; /*火狐FF*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  -o-user-select: none; /*opera浏览器*/
  user-select: none;
}

::v-deep .essential_information .companyName .el-input {
  width: 596px;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
::v-deep .label_code .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #333333;
}
::v-deep .radio_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}
.addenterprise {
  width: 100%;
  background: #ffffff;

  .input_flex {
    margin-top: 20px;
    .radio_flex {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
  .label_code {
    padding: 30px 0px;
  }
  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }
  .store_table {
    height: 70px;
    box-sizing: border-box;
    margin: 0 72px 20px 0;
    padding-left: 10px;
    display: flex;
    border-bottom: 1px solid #efefef;

    .store_table_item {
      color: #999999;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-bottom: 2px solid #4e93fb;
      border-radius: 1px;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .dialog-footer {
    border-top: 1px solid #f5f5f5;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.readonly_copyable {
  /* 允许用户选择并复制文本 */
  user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -ms-user-select: text;
  /* 移除禁用状态的视觉效果（可选） */
  background-color: #fff; /* 或其他颜色 */
  cursor: text; /* 更改光标样式为文本选择光标 */
}
</style>
